const { GAME_ACTION } = require('../const')

function unitChangeTile({ action, board, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_CHANGE_TILE) {
        const { unit_id, tile_id } = params
        action.patch = { units: { [unit_id]: { tile_id } } }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        const flag_id = board.getFlagByTile({ tile_id })
        const team_id = board.getTeamByUnit({ unit_id })
        if (
            flag_id !== undefined &&
            board.state.flags[flag_id].team_id !== team_id
        ) {
            addReaction({
                type: GAME_ACTION.FLAG_CAPTURED,
                params: { flag_id, team_id, unit_id },
            })
        }
    }
}

module.exports = unitChangeTile
