const { GAME_ACTION } = require('../const')

function cardReactionRemove({ action, dop, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.CARD_REACTION_REMOVE) {
        action.patch = {
            temp: {
                [params.uid]: dop.TYPE.Delete(),
            },
        }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        if (Array.isArray(params.reactions))
            params.reactions.forEach(addReaction)
    }
}

module.exports = cardReactionRemove
