const { GAME_ACTION, GAME_EVENT } = require('../const/')

const card = {
    create,
    validate,
    options,
    bot,
    rule,
    basepower: 20,
    ratio: 0.3,
    type: 'WOODSHIELD',
    title: 'Wood Shield',
    description:
        'The selected unit will use the shield to block the next attack.',
}

module.exports = card

function create({ emitter, board, player_id }) {
    const state = {}

    // PUBLIC
    // PUBLIC
    // PUBLIC
    function init() {
        emitter.emit(GAME_EVENT.MESSAGE_SET, {
            message: 'Select one unit',
        })
        emitter.on(GAME_EVENT.TOUCH_CLICK, click)
        update()
    }

    function update() {
        clear()
        const tiles = options({ board, player_id }).map(
            ({ unit_id }) => board.state.units[unit_id].tile_id
        )
        state.tiles = tiles
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
            tiles: tiles.map((tile_id) => ({ tile_id })),
        })
        // emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
    }

    function destroy() {
        clear()
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.remove(GAME_EVENT.TOUCH_CLICK, click)
    }

    // PRIVATE
    // PRIVATE
    // PRIVATE
    function clear() {
        if (state.tiles !== undefined) {
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE, {
                tiles: state.tiles,
            })
        }
    }

    function click({ position }) {
        const tile_id = board.positionToTile(position)
        const params = { tile_id }
        const valid = validate({ board, player_id, params })
        if (valid !== undefined) {
            emitter.emit(GAME_EVENT.CARD_USING, params)
        }
        // else {
        //     emitter.emit(GAME_EVENT.CARD_CANCEL, {})
        // }
    }

    return {
        state,
        init,
        update,
        destroy,
    }
}

// SERVER
// SERVER
// SERVER

function options({ board, player_id }) {
    const already = Object.keys(board.state.temp)
        .filter((uid) => {
            const temp = board.state.temp[uid]
            return (
                temp.card_type === card.type &&
                board.state.units.hasOwnProperty(temp.subparams.unit_id)
            )
        })
        .map((uid) => board.state.temp[uid].subparams.unit_id)

    const team_id = board.getTeamByPlayer({ player_id })
    return board
        .getUnitsByTeam({ team_id })
        .filter((unit_id) => !already.includes(unit_id))
        .map((unit_id) => ({ unit_id }))
}

function validate({ player_id, board, params }) {
    const valid = options({ board, player_id })
        .map(({ unit_id }) => board.state.units[unit_id].tile_id)
        .includes(params.tile_id)

    if (valid) {
        return { unit_id: board.getUnitByTile({ tile_id: params.tile_id }) }
    }
}

function bot({ board, action }) {
    if (action.type === GAME_ACTION.UNIT_ATTACK) {
        const { unit_id, unit_id_target } = action.params
        const unit = board.state.units[unit_id]
        const enemy = board.state.units[unit_id_target]
        if (unit.life < unit.maxlife || enemy.bonus.includes(unit.unit_type)) {
            return 1
        }
    }
    return 0
}

function rule({ action, board, reactions, addReaction, removeReaction }) {
    const { type, params, uid } = action
    const icon = `card_${card.type.toLowerCase()}`

    // ACTIVE
    if (type === GAME_ACTION.CARD_USE && params.card_type === card.type) {
        const { unit_id } = params.subparams
        addReaction({
            type: GAME_ACTION.CARD_REACTION_ADD,
            params: {
                uid,
                card_type: card.type,
                subparams: params.subparams,
                reactions: [
                    {
                        type: GAME_ACTION.UNIT_ICON_ADD,
                        params: {
                            uid,
                            icon,
                            unit_id,
                        },
                    },
                ],
            },
        })
    }

    // ATTACK
    else if (type === GAME_ACTION.UNIT_ATTACK) {
        Object.keys(board.state.temp).forEach((uid, index) => {
            const temp = board.state.temp[uid]
            if (
                temp !== undefined &&
                temp.card_type === card.type &&
                temp.subparams.unit_id === params.unit_id_target
            ) {
                const rattack = reactions.find(
                    (r) => r.type === GAME_ACTION.UNIT_ATTACK
                )
                rattack.params.damage = 0

                // REMOVING RELATED CARD REACTIONS
                reactions
                    .slice(0)
                    .reverse()
                    .filter(
                        (action) =>
                            (action.params.hasOwnProperty('subparams') &&
                                action.params.subparams.hasOwnProperty(
                                    'unit_id'
                                ) &&
                                action.params.subparams.unit_id ===
                                    temp.subparams.unit_id) ||
                            (action.params.hasOwnProperty('unit_id') &&
                                action.params.unit_id ===
                                    temp.subparams.unit_id)
                    )
                    .forEach(removeReaction)

                // REMOVING REACTIONS
                removeReaction(
                    reactions.find((r) => r.type === GAME_ACTION.UNIT_REMOVE)
                )
                removeReaction(
                    reactions.find((r) => r.type === GAME_ACTION.UNIT_DEATH)
                )
                removeReaction(
                    reactions.find((r) => r.type === GAME_ACTION.UNIT_LIFE)
                )

                addReaction({
                    type: GAME_ACTION.CARD_REACTION_REMOVE,
                    params: {
                        uid,
                        reactions: [
                            {
                                type: GAME_ACTION.UNIT_ICON_REMOVE,
                                params: {
                                    uid,
                                    icon,
                                    unit_id: temp.subparams.unit_id,
                                },
                            },
                        ],
                    },
                })
            }
        })
    }
}
