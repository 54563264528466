const { GAME_ACTION } = require('../const')

function unitDeath({ action, addReaction, board, dop }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_DEATH) {
        const { unit_id } = params
        addReaction({
            type: GAME_ACTION.UNIT_REMOVE,
            params: { unit_id },
        })
    }
}

module.exports = unitDeath
