const { GAME_ACTION, GAME_EVENT } = require('../const/')
const WOODSHIELD = require('./woodshield')
const POISONEDARROW = require('./poisonedarrow')

const card = {
    create,
    validate,
    options,
    bot,
    rule,
    basepower: 30,
    ratio: 0.2,
    type: 'ADRENALINE',
    title: 'Adrenaline',
    description:
        "The unit's damage is increased by one point for the next attack.",
}

module.exports = card

const increment = 1

function create({ emitter, board, player_id }) {
    const state = {}

    // PUBLIC
    // PUBLIC
    // PUBLIC
    function init() {
        emitter.emit(GAME_EVENT.MESSAGE_SET, {
            message: 'Select one unit',
        })
        emitter.on(GAME_EVENT.TOUCH_CLICK, click)
        update()
    }

    function update() {
        clear()
        const tiles = options({ board, player_id }).map(
            ({ unit_id }) => board.state.units[unit_id].tile_id
        )
        state.tiles = tiles
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
            tiles: tiles.map((tile_id) => ({ tile_id })),
        })
        // emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
    }

    function destroy() {
        clear()
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.remove(GAME_EVENT.TOUCH_CLICK, click)
    }

    // PRIVATE
    // PRIVATE
    // PRIVATE
    function clear() {
        if (state.tiles !== undefined) {
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE, {
                tiles: state.tiles,
            })
        }
    }

    function click({ position }) {
        const tile_id = board.positionToTile(position)
        const params = { tile_id }
        const valid = validate({ board, player_id, params })
        if (valid !== undefined) {
            emitter.emit(GAME_EVENT.CARD_USING, params)
        }
    }

    return {
        state,
        init,
        update,
        destroy,
    }
}

function options({ board, player_id }) {
    const team_id = board.getTeamByPlayer({ player_id })
    return board.getUnitsByTeam({ team_id }).map((unit_id) => ({ unit_id }))
}

function validate({ board, player_id, params }) {
    const valid = options({ board, player_id })
        .map(({ unit_id }) => board.state.units[unit_id].tile_id)
        .includes(params.tile_id)

    if (valid) {
        return { unit_id: board.getUnitByTile({ tile_id: params.tile_id }) }
    }
}

function bot({ board, action }) {
    if (action.type === GAME_ACTION.UNIT_ATTACK) {
        const { unit_id, unit_id_target } = action.params
        const unit = board.state.units[unit_id]
        const enemy = board.state.units[unit_id_target]
        const bonus = unit.bonus.includes(enemy.unit_type) ? 1 : 0
        const woodshield = Object.keys(board.state.temp).find(
            (id) =>
                board.state.temp[id].card_type === WOODSHIELD.type &&
                board.state.temp[id].subparams.unit_id === unit_id_target
        )
        const poisonedarrow = Object.keys(board.state.temp).find(
            (id) =>
                board.state.temp[id].card_type === POISONEDARROW.type &&
                (board.state.temp[id].subparams.unit_id === unit_id ||
                    board.state.temp[id].subparams.unit_id === unit_id_target)
        )

        if (
            woodshield !== undefined ||
            poisonedarrow !== undefined ||
            unit.damage + bonus >= enemy.life
        ) {
            return 0
        } else if (unit.damage + bonus + increment >= enemy.life) {
            return 1
        } else {
            return 0.5
        }
    }
    return 0
}

function rule({ action, board, applyPatch, addReaction, dop }) {
    const { type, params, uid } = action
    const icon = `card_${card.type.toLowerCase()}`

    // ACTIVE
    if (type === GAME_ACTION.CARD_USE && params.card_type === card.type) {
        const { unit_id } = params.subparams
        const { damage } = board.state.units[unit_id]
        addReaction({
            type: GAME_ACTION.CARD_REACTION_ADD,
            params: {
                uid,
                card_type: card.type,
                subparams: params.subparams,
                reactions: [
                    {
                        type: GAME_ACTION.BASIC_PATCH,
                        params: {
                            units: {
                                [unit_id]: { damage: damage + increment },
                            },
                        },
                    },
                    {
                        type: GAME_ACTION.UNIT_ICON_ADD,
                        params: {
                            uid,
                            icon,
                            unit_id,
                        },
                    },
                ],
            },
        })
    }

    // ATTACK
    else if (type === GAME_ACTION.UNIT_ATTACK) {
        Object.keys(board.state.temp).forEach((uid) => {
            const temp = board.state.temp[uid]
            if (
                temp.card_type === card.type &&
                temp.subparams.unit_id === params.unit_id
            ) {
                const { unit_id } = params
                const { damage } = board.state.units[unit_id]
                addReaction({
                    type: GAME_ACTION.CARD_REACTION_REMOVE,
                    params: {
                        uid,
                        reactions: [
                            {
                                type: GAME_ACTION.BASIC_PATCH,
                                params: {
                                    units: {
                                        [unit_id]: {
                                            damage: damage - increment,
                                        },
                                    },
                                },
                            },
                            {
                                type: GAME_ACTION.UNIT_ICON_REMOVE,
                                params: {
                                    uid,
                                    icon,
                                    unit_id: params.unit_id,
                                },
                            },
                        ],
                    },
                })
            }
        })
    }
}
