const { GAME_ACTION, UNIT_TYPE } = require('../const')
const { randomInt } = require('../utils')

function recruitAuto({ action, board, addReaction }) {
    const { type, params } = action
    const { player_id } = params

    if (type === GAME_ACTION.RECRUIT_AUTO || type === GAME_ACTION.TURN_END) {
        while (board.isValidToRecruit({ player_id })) {
            const unit_types = Object.keys(UNIT_TYPE)
            const unit_type =
                UNIT_TYPE[unit_types[randomInt(0, unit_types.length - 1)]]
            const [tile_id] = board.getTilesCanRecruit({ player_id })
            addReaction({
                type: GAME_ACTION.RECRUIT,
                params: { player_id, unit_type, tile_id },
            })
        }
    }
}

module.exports = recruitAuto
