const { GAME_ACTION } = require('../const')

function unitLife({ action, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_LIFE) {
        const { unit_id, life } = params
        action.patch = { units: { [unit_id]: { life } } }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        if (life <= 0) {
            addReaction({
                type: GAME_ACTION.UNIT_DEATH,
                params: { unit_id },
            })
        }
    }
}

module.exports = unitLife
