const { GAME_ACTION, TIMES } = require('../const')

function turnStart({ applyPatch, action, board }) {
    const { type, params } = action
    if (type === GAME_ACTION.TURN_START) {
        const { team_id } = params
        const players = {}
        Object.keys(board.state.players).forEach((player_id) => {
            const player = board.state.players[player_id]
            if (player.team_id === team_id) {
                players[player_id] = {
                    turn_started: board.now() + TIMES.MARGIN_TURN_START,
                }
            }
        })

        const units = {}
        Object.keys(board.state.units).forEach((unit_id) => {
            // console.log({ unit_id }, board.getTeamByUnit({ unit_id }), team_id)
            // if (board.getTeamByUnit({ unit_id }) === team_id) {
            const unit = board.state.units[unit_id]
            if (unit.walked) {
                units[unit_id] = { walked: false }
            }
            if (unit.attacked) {
                units[unit_id] === undefined
                    ? (units[unit_id] = { walked: false, attacked: false })
                    : (units[unit_id].attacked = false)
            }
            // }
        })

        action.patch = {
            turn: board.state.turn + 1,
            units,
            players,
        }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = turnStart
