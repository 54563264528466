const { GAME_ACTION } = require('../const')
const { createUniqueId } = require('../utils')
const UNITS = require('../units')

function recruit({ action, addReaction, applyPatch, board }) {
    const { type, params } = action
    if (type === GAME_ACTION.RECRUIT) {
        const { player_id, unit_type, tile_id } = params
        const unit_id = createUniqueId({
            length: 2,
            list: Object.keys(board.state.units),
        })
        const unit = UNITS[unit_type]({ players: [player_id], tile_id })

        action.patch = {
            players: {
                [player_id]: {
                    recruited: board.state.players[player_id].recruited + 1,
                },
            },
        }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        addReaction({
            type: GAME_ACTION.UNIT_ADD,
            params: { unit_id, unit },
        })
    }
}

module.exports = recruit
