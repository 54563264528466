const { UNIT_TYPE } = require('../const')

function SLINGER({ players, tile_id }) {
    return {
        unit_type: UNIT_TYPE.SLINGER,
        bonus: [UNIT_TYPE.ARCHER, UNIT_TYPE.AXEMAN],
        players,
        tile_id,
        life: 3,
        maxlife: 3,
        movement: 2,
        range: [3, 3],
        damage: 1,
        walked: false,
        attacked: false,
        icons: {},
    }
}

module.exports = SLINGER
