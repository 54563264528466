const { UNIT_TYPE } = require('../const')

function ARCHER({ players, tile_id }) {
    return {
        unit_type: UNIT_TYPE.ARCHER,
        bonus: [UNIT_TYPE.AXEMAN, UNIT_TYPE.SPEARMAN],
        players,
        tile_id,
        life: 3,
        maxlife: 3,
        movement: 2,
        range: [4, 4],
        damage: 1,
        walked: false,
        attacked: false,
        icons: {},
    }
}

module.exports = ARCHER
