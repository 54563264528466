const { GAME_ACTION } = require('../const')

function setBoard({ action, applyPatch }) {
    const { type, params } = action
    if (type === GAME_ACTION.SET_BOARD) {
        action.patch = params
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = setBoard
