const { GAME_ACTION } = require('../const')

function cardReactionAdd({ action, dop, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.CARD_REACTION_ADD) {
        if (params.hasOwnProperty('subparams')) {
            action.patch = {
                temp: {
                    [params.uid]: {
                        card_type: params.card_type,
                        step: params.step || 1,
                        subparams: params.subparams,
                    },
                },
            }

            const { unpatch } = applyPatch(action.patch)
            action.unpatch = unpatch
        }

        if (Array.isArray(params.reactions))
            params.reactions.forEach(addReaction)
    }
}

module.exports = cardReactionAdd
