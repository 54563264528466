const glicko2 = require('glicko2-lite')

function createRating({ rating = 1000, rd = 200, vol = 0.06 } = {}) {
    return {
        rating,
        rd,
        vol,
    }
}

function calcRating({ me, enemy, result, tau = 0.5 }) {
    return {
        me: glicko2(
            me.rating,
            me.rd,
            me.vol,
            [[enemy.rating, enemy.rd, result]],
            { tau }
        ),
        enemy: glicko2(
            enemy.rating,
            enemy.rd,
            enemy.vol,
            [[me.rating, me.rd, result == 0.5 ? 0.5 : result === 1 ? 0 : 1]],
            { tau }
        ),
    }
}

function getAverageRating(team) {
    const l = team.length
    return {
        rating: team.reduce((r, p) => r + p.rating, 0) / l,
        rd: team.reduce((r, p) => r + p.rd, 0) / l,
        vol: team.reduce((r, p) => r + p.vol, 0) / l,
    }
}

module.exports = {
    createRating,
    calcRating,
    getAverageRating,
}

// glicko.predict = function (p1, p2) {
//     const g = (rd) =>
//         1 / Math.sqrt(1 + (3 * Math.pow(rd, 2)) / Math.pow(Math.PI, 2))
//     const diffrd = Math.sqrt(Math.pow(p1.rd, 2) + Math.pow(p2.rd, 2))
//     return 1 / (1 + Math.exp(-1 * g(diffrd) * (p1.rating - p2.rating)))
// }
// const player1 = glicko.createRating({ rating: 2000 })
// const player2 = glicko.createRating({ rating: 1500 })
// const player3 = glicko.createRating({ rating: 1750 })
// const player4 = glicko.createRating({ rating: 1750 })
// const team1 = glicko.getAverageRating([player1, player2])
// const team2 = glicko.getAverageRating([player3, player4])
// const { me, enemy } = glicko.calcRating({
//     me: player1,
//     enemy: team2,
//     result: 1,
// })
// console.log(me)
// console.log(enemy)
// console.log(predict(team1, team2))

// const player1 = elo.createRating({ rating: 1500 })
// const player2 = elo.createRating({ rating: 1500 })
// const { me, enemy } = elo.calcRating({
//     me: player1,
//     enemy: player2,
//     result: 1,
// })
// console.log(me)
// console.log(enemy)
