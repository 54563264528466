const { UNIT_TYPE } = require('../const')

function SPEARMAN({ players, tile_id }) {
    return {
        unit_type: UNIT_TYPE.SPEARMAN,
        bonus: [UNIT_TYPE.KNIGHT, UNIT_TYPE.CROSSBOWOMAN],
        players,
        tile_id,
        life: 4,
        maxlife: 4,
        movement: 2,
        range: [1, 2],
        damage: 1,
        walked: false,
        attacked: false,
        icons: {},
    }
}

module.exports = SPEARMAN
