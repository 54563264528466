// https://gist.github.com/Josema/c3f8958c596aca2f98c053819e233d1f

function createEventEmitter() {
    const events = {}
    return {
        on: (name, listener) => {
            if (typeof name !== 'string') {
                throw new Error(`Not valid name ${name}:${typeof name}`)
            }

            if (typeof listener !== 'function') {
                throw new Error(`Not valid listener ${name} ${typeof listener}`)
            }

            const listeners = events.hasOwnProperty(name)
                ? events[name]
                : (events[name] = [])
            listeners.push(listener)
        },
        emit: (name, ...params) => {
            const listeners = events[name] || []
            return listeners.map((listener) => {
                return listener(...params)
            })
        },
        remove: (name, listener) => {
            const index = events[name].indexOf(listener)
            events[name].splice(index, 1)
        },
        removeAll: () => {
            Object.keys(events).forEach((name) => {
                delete events[name]
            })
        },
    }
}

module.exports = createEventEmitter

// const emitter = createEventEmitter()
// function listener(a, b) {
//     console.log(a, b)
// }
// emitter.on('myevent', listener)
// emitter.emit('myevent', 1, 2)
// emitter.remove('myevent', listener)
// emitter.emit('myevent', 1, 2)
