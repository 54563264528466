const { GAME_ACTION, TEAM } = require('../const')

function turnEnd({ applyPatch, addReaction, action, board, dop }) {
    const { type, params } = action
    if (type === GAME_ACTION.TURN_END) {
        const { player_id } = params
        const team_id = board.getTeamByPlayer({ player_id })
        const team_enemy = team_id === TEAM.TEAM_1 ? TEAM.TEAM_2 : TEAM.TEAM_1
        const { players } = board.state
        let players_playing = 0
        let turn_bonus = undefined

        Object.keys(players)
            .filter((pid) => players[pid].team_id === team_id)
            .forEach((pid) => {
                const status = board.getTurnStatusByPlayer({ player_id: pid })

                if (status.is_turn_team) {
                    if (player_id === pid) {
                        turn_bonus = status.new_bonus
                    } else {
                        players_playing += 1
                    }
                }

                // console.log(board.getTurnStatusByPlayer({ player_id: pid }))
            })

        action.patch = {
            players: {
                [player_id]: { turn_started: null, turn_bonus },
            },
        }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        if (players_playing === 0) {
            addReaction({
                type: GAME_ACTION.TURN_START,
                params: { team_id: team_enemy },
            })
        }
    }
}

module.exports = turnEnd
