const { GAME_ACTION } = require('../const')

function flagAdd({ action, applyPatch }) {
    const { type, params } = action
    if (type === GAME_ACTION.FLAG_ADD) {
        const { flag_id, tile_id } = params
        action.patch = { flags: { [flag_id]: { tile_id } } }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = flagAdd
