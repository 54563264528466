const { GAME_ACTION } = require('../const')

function unitAdd({ action, applyPatch }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_ADD) {
        const { unit_id, unit } = params
        action.patch = { units: { [unit_id]: unit } }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = unitAdd
