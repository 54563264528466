const AStar = require('dynamic-astar').default
const { getTileId } = require('./game')

module.exports = function findPath(origin, destiny, isWalkableAt) {
    const firstNode = buildNode(
        {
            cost: calcCost(origin, destiny),
            destiny,
        },
        origin
    )
    return AStar(firstNode, (from) => getNeighbours(from, isWalkableAt))
}

function buildNode(from, to) {
    const { destiny } = from
    const { x, y } = to
    return {
        id: getTileId(x, y),
        cost: from.cost + calcCost(from, to),
        costEstimation: estimation(to, destiny),
        x,
        y,
        destiny,
    }
}

function calcCost(from, to) {
    const isDiagonal = from.x !== to.x && from.y !== to.y
    return isDiagonal ? Math.sqrt(2) : 1
}

function estimation(to, destiny) {
    const dx = Math.abs(to.x - destiny.x)
    const dy = Math.abs(to.y - destiny.y)
    const maxDist = Math.max(dx, dy)
    const minDist = Math.min(dx, dy)
    return minDist * Math.sqrt(2) + maxDist - minDist
}

function* getNeighbours(from, isWalkableAt) {
    const { x, y } = from
    // ↑
    if (isWalkableAt(from, { x, y: y - 1 })) {
        yield buildNode(from, { x, y: y - 1 })
    }
    // →
    if (isWalkableAt(from, { x: x + 1, y })) {
        yield buildNode(from, { x: x + 1, y })
    }
    // ↓
    if (isWalkableAt(from, { x, y: y + 1 })) {
        yield buildNode(from, { x, y: y + 1 })
    }
    // ←
    if (isWalkableAt(from, { x: x - 1, y })) {
        yield buildNode(from, { x: x - 1, y })
    }
    // ↗
    if (isWalkableAt(from, { x: x + 1, y: y - 1 })) {
        yield buildNode(from, { x: x + 1, y: y - 1 })
    }
    // ↘
    if (isWalkableAt(from, { x: x + 1, y: y + 1 })) {
        yield buildNode(from, { x: x + 1, y: y + 1 })
    }
    // ↙
    if (isWalkableAt(from, { x: x - 1, y: y + 1 })) {
        yield buildNode(from, { x: x - 1, y: y + 1 })
    }
    // ↖
    if (isWalkableAt(from, { x: x - 1, y: y - 1 })) {
        yield buildNode(from, { x: x - 1, y: y - 1 })
    }
}
