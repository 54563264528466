const { GAME_ACTION } = require('../const')

function unitRemove({ action, applyPatch, dop }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_REMOVE) {
        const { unit_id } = params
        action.patch = { units: { [unit_id]: dop.TYPE.Delete() } }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = unitRemove
