const RULES = require('../rules/')
const { uid } = require('../utils/')

function Actions({ board, rules = RULES, dop }) {
    function applyPatch(patch) {
        return dop.applyPatch(board.state, patch)
    }

    function createAction(action, reactions = []) {
        action.uid = action.uid || uid()
        reactions.push(action)

        const from = reactions[reactions.indexOf(action)].uid

        rules.forEach((rule) =>
            rule({
                dop,
                board,
                rules,
                action,
                reactions,
                applyPatch,
                addReaction: (reaction) =>
                    createAction({ ...reaction, from }, reactions),
                removeReaction: (reaction) => {
                    const index = reactions.indexOf(reaction)
                    if (index > -1) {
                        if (reaction.hasOwnProperty('unpatch')) {
                            applyPatch(reaction.unpatch)
                        }
                        reactions.splice(index, 1)
                        return true
                    }
                    return false
                },
            })
        )

        return reactions
    }

    function applyReactions(reactions) {
        reactions.forEach((reaction) => {
            if (reaction.hasOwnProperty('patch')) {
                applyPatch(reaction.patch)
            }
        })
    }

    function unapplyReactions(reactions) {
        reactions
            .slice(0)
            .reverse()
            .forEach((reaction) => {
                if (reaction.hasOwnProperty('unpatch')) {
                    applyPatch(reaction.unpatch)
                    // const reactions_redo = createAction({ ...reaction.unpatch })
                    // delete reactions_redo[0].patch
                    // delete reactions_redo[0].unpatch
                    // if (redo.length > 0) {
                    //     delete reactions_redo[0].uid
                    //     reactions_redo[0].from = 0
                    // }
                    // redo.push(reactions_redo[0])
                }
            })
    }

    // function redoAction(reactions) {
    //     reactions.forEach((reaction) => {
    //         if (reaction.hasOwnProperty('patch')) {
    //             applyPatch(reaction.patch)
    //         }
    //     })
    // }

    return {
        applyPatch,
        createAction,
        applyReactions,
        unapplyReactions,
    }
}

module.exports = Actions
