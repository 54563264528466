// Cards
const POISONEDARROW = require('./poisonedarrow')
const DOWNWIND = require('./downwind')
const WOODSHIELD = require('./woodshield')
const HOLYBERRIES = require('./holyberries')
const ADRENALINE = require('./adrenaline')

const CARDS = {
    POISONEDARROW,
    DOWNWIND,
    WOODSHIELD,
    HOLYBERRIES,
    ADRENALINE,
}

module.exports = CARDS
