const { ethers } = require('./ethers')
const CONSTS = require('./const')
const ABI_ERC20 = require('./abi/erc20.json')
const ABI_CONKISCARDS = require('./abi/conkiscards.json')

function getJsonProvider({ network }) {
    return new ethers.JsonRpcProvider(network.rpcUrls[0], {
        chainId: Number(network.chainId),
        name: network.chainName,
    })
}

async function getBalance({ provider, address, decimals = 18 }) {
    const balance = await provider.getBalance(address)
    return ethers.formatUnits(balance, decimals)
}

async function getBalanceToken({ provider, address, contract_address }) {
    const contract = getContractErc20({ provider, contract_address })
    const decimals = await contract.decimals()
    const balance = await contract.balanceOf(address)
    return ethers.formatUnits(balance, decimals)
}

function getContract({ provider, contract_address, abi }) {
    return new ethers.Contract(contract_address, abi, provider)
}

function getContractErc20({ provider, contract_address }) {
    return getContract({ provider, contract_address, abi: ABI_ERC20 })
}

function getContractConkisCards({ provider, contract_address }) {
    return getContract({ provider, contract_address, abi: ABI_CONKISCARDS })
}

async function sendCoins({ signer, address_to, amount, decimals = 18 }) {
    const transaction = {
        to: address_to,
        value: ethers.parseUnits(amount, decimals),
    }
    const receipt = await signer.sendTransaction(transaction)
    return receipt
}

// UTILS
// UTILS
// UTILS

function getShorterAddress(addr, left = 8, right = 5) {
    return `${addr.slice(0, left)} ... ${addr.slice(-right)}`
}

function formatNumberDecimals(number, decimals) {
    const rounded = parseFloat(number.toFixed(decimals))
    return rounded === parseInt(rounded, 10)
        ? rounded.toString()
        : rounded.toFixed(decimals)
}

function formatNumberPrice(number, price) {
    const decimals = String(Math.round(price)).length + 1
    return formatNumberDecimals(number, decimals)
}

module.exports = {
    ...CONSTS,
    ethers,
    // tx / requests
    getBalance,
    getBalanceToken,
    sendCoins,
    getContract,
    getContractErc20,
    getContractConkisCards,
    // utils / pure
    getJsonProvider,
    getShorterAddress,
    formatNumberDecimals,
    formatNumberPrice,
}
