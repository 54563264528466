const { GAME_ACTION } = require('../const')

function flagCaptured({ action, board, applyPatch, addReaction, dop }) {
    const { type, params } = action
    if (type === GAME_ACTION.FLAG_CAPTURED) {
        const { flag_id, team_id, unit_id } = params
        action.patch = {
            flags: {
                [flag_id]: { team_id },
            },
        }

        // This must be a reaction:
        // addReaction({ type: GAME_ACTION.POWER_INCREMENT, params: { player_id, increment: power_by_flag } })
        if (unit_id !== undefined) {
            const player_id = board.getPlayerByUnit({ unit_id })
            const { power } = board.state.players[player_id]
            const { power_by_flag } = board.state
            action.patch.players = {
                [player_id]: { power: power + power_by_flag },
            }
        }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = flagCaptured
