const { GAME_RATING_RESULT } = require('../const')
const algorithm = 'glicko'

function getUserTrophies(user) {
    return getTrophiesFromRating(user[algorithm].rating)
}

function getTrophiesFromRating(rating) {
    return Math.round((rating * 10000) / 2000)
}

function calcNewTrophies({ trophies, rating_before, rating_after, result }) {
    if (result === GAME_RATING_RESULT.DRAW) {
        return trophies
    }

    const trophies_inc = getTrophiesFromRating(rating_after - rating_before)
    const trophies_rating = getTrophiesFromRating(rating_after)
    const deviation = calcDeviation({ trophies, rating: rating_after }) / 100
    const trophies_diff = trophies_rating - trophies
    const bonus =
        (result === GAME_RATING_RESULT.WIN && trophies_diff > 0) ||
        (result === GAME_RATING_RESULT.LOSE && trophies_diff < 0)
            ? trophies_diff / 20
            : trophies_inc * deviation * Math.sign(-deviation)

    return Math.max(0, Math.round(trophies + (trophies_inc + bonus)))
}

function calcDeviation({ trophies, rating }) {
    return Math.round(100 - (trophies * 100) / getTrophiesFromRating(rating))
}

module.exports = {
    algorithm,
    getUserTrophies,
    calcNewTrophies,
}

// glicko.predict = function (p1, p2) {
//     const g = (rd) =>
//         1 / Math.sqrt(1 + (3 * Math.pow(rd, 2)) / Math.pow(Math.PI, 2))
//     const diffrd = Math.sqrt(Math.pow(p1.rd, 2) + Math.pow(p2.rd, 2))
//     return 1 / (1 + Math.exp(-1 * g(diffrd) * (p1.rating - p2.rating)))
// }
// const player1 = glicko.createRating({ rating: 2000 })
// const player2 = glicko.createRating({ rating: 1500 })
// const player3 = glicko.createRating({ rating: 1750 })
// const player4 = glicko.createRating({ rating: 1750 })
// const team1 = glicko.getAverageRating([player1, player2])
// const team2 = glicko.getAverageRating([player3, player4])
// const { me, enemy } = glicko.calcRating({
//     me: player1,
//     enemy: team2,
//     result: 1,
// })
// console.log(me)
// console.log(enemy)
// console.log(predict(team1, team2))

// const player1 = elo.createRating({ rating: 1500 })
// const player2 = elo.createRating({ rating: 1500 })
// const { me, enemy } = elo.calcRating({
//     me: player1,
//     enemy: player2,
//     result: 1,
// })
// console.log(me)
// console.log(enemy)
