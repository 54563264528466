const KNIGHT = require('./knight')
const SPEARMAN = require('./spearman')
const AXEMAN = require('./axeman')
const ARCHER = require('./archer')
const SLINGER = require('./slinger')
const CROSSBOWOMAN = require('./crossbowoman')

module.exports = {
    KNIGHT,
    SPEARMAN,
    AXEMAN,
    ARCHER,
    SLINGER,
    CROSSBOWOMAN,
}
