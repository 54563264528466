const { GAME_ACTION } = require('../const')

function iconUnitRemove({ action, board, dop, applyPatch }) {
    const { type, params } = action
    if (
        type === GAME_ACTION.UNIT_ICON_REMOVE &&
        board.state.units.hasOwnProperty(params.unit_id)
    ) {
        const { unit_id, uid } = params
        action.patch = {
            units: {
                [unit_id]: { icons: { [uid]: dop.TYPE.Delete() } },
            },
        }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = iconUnitRemove
