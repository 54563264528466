// https://chainlist.org/?chain=1&search=telos
const CHAIN = {
    TELOS: '0x28',
    TELOS_TESTNET: '0x29',
    // ETHEREUM: '0x1',
}

const CHAINS = {
    [CHAIN.TELOS]: {
        chainId: CHAIN.TELOS,
        chainName: 'Telos',
        rpcUrls: ['https://mainnet.telos.net/evm'],
        blockExplorerUrls: ['https://teloscan.io'],
        nativeCurrency: {
            name: 'TELOS',
            symbol: 'TLOS',
            decimals: 18,
        },
    },
    // [CHAIN.TELOS_TESTNET]: {
    //     chainId: CHAIN.TELOS_TESTNET,
    //     chainName: 'Telos Testnet',
    //     rpcUrls: ['https://testnet.telos.net/evm'],
    //     blockExplorerUrls: ['https://testnet.teloscan.io'],
    //     nativeCurrency: {
    //         name: 'TELOS',
    //         symbol: 'TLOS',
    //         decimals: 18,
    //     },
    // },
    // [CHAIN.ETHEREUM]: {
    //     chainId: CHAIN.ETHEREUM,
    //     chainName: 'Ethereum',
    //     rpcUrls: ['https://mainnet.infura.io/v3/'],
    //     blockExplorerUrls: ['https://etherscan.io'],
    //     nativeCurrency: {
    //         name: 'Ether',
    //         symbol: 'ETH',
    //         decimals: 18,
    //     },
    // },
}

const COINS = [
    {
        id: 1,
        cmk_id: 4660, // coinmarketcap
        name: CHAINS[CHAIN.TELOS].chainName,
        symbol: 'TLOS',
        decimals: 18,
        chain_id: CHAIN.TELOS,
    },
    // {
    //     id: 2,
    //     cmk_id: 4660, // coinmarketcap
    //     name: CHAINS[CHAIN.TELOS_TESTNET].chainName,
    //     symbol: 'TLOS',
    //     decimals: 18,
    //     chain_id: CHAIN.TELOS_TESTNET,
    // },
    // {
    //     id: 3,
    //     cmk_id: 825, // coinmarketcap
    //     name: 'USDT',
    //     symbol: 'USDT',
    //     decimals: 6,
    //     chain_id: CHAIN.ETHEREUM,
    //     smartcontract: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    // },
    // {
    //     id: 4,
    //     cmk_id: 3408, // coinmarketcap
    //     name: 'USDC',
    //     symbol: 'USDC',
    //     decimals: 6,
    //     chain_id: CHAIN.ETHEREUM,
    //     smartcontract: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    // },
]

const SMART_CONTRACTS = {
    [CHAIN.TELOS]: {
        CARDS: '0xba48921b13E972756Cf5105C905BA990e8D54862',
    },
    [CHAIN.TELOS_TESTNET]: {
        CARDS: '0xDe7B0C87a37B2B9f3a7D1C3080BDe64eEe9B8e02',
    },
}

module.exports = {
    CHAIN,
    CHAINS,
    COINS,
    SMART_CONTRACTS,
}
