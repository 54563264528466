//

function getGameXp({ xp, trophies }) {
    return Math.round(xp + 100 + trophies / 10)
}

function getLevel(xp) {
    const n = 0.1 * Math.sqrt(xp)
    const level = Math.floor(n)
    const xpnext = Math.pow(level + 1, 2) * 100
    const xpprev = Math.pow(level, 2) * 100
    const percent = ((xp - xpprev) * 100) / (xpnext - xpprev)
    return { xp, level: level + 1, percent, n, xpnext, xpprev }
}

function getXpRange(level) {
    const min = Math.pow(10 * (level - 1), 2)
    const max = Math.pow(10 * level, 2) - 1
    return [min, max]
}

module.exports = {
    getGameXp,
    getLevel,
    getXpRange,
}

// console.log(getGameXp({ trophies: 200 }))
// console.log(getGameXp({ trophies: -100 }))
// console.log(getLevel(200))
// console.log(getLevel(500))
// console.log(getLevel(905))
