const { UNIT_TYPE } = require('../const')

function AXEMAN({ players, tile_id }) {
    return {
        unit_type: UNIT_TYPE.AXEMAN,
        bonus: [UNIT_TYPE.SPEARMAN, UNIT_TYPE.KNIGHT],
        players,
        tile_id,
        life: 5,
        maxlife: 5,
        movement: 2,
        range: [1, 1],
        damage: 1,
        walked: false,
        attacked: false,
        icons: {},
    }
}

module.exports = AXEMAN
