const { GAME_ACTION } = require('../const')

function cardUse({ action, board, applyPatch, addReaction }) {
    const { type, params } = action
    const { player_id, card_id } = params

    if (
        type === GAME_ACTION.CARD_USE &&
        player_id !== undefined &&
        card_id !== undefined
    ) {
        const { cards, power } = board.state.players[player_id]
        const index = cards.findIndex((c) => c._id === card_id)
        const card = cards[index]

        // This must be a reaction:
        // addReaction({ type: GAME_ACTION.POWER_INCREMENT, params: { player_id, increment: -card.power } })
        action.patch = {
            players: {
                [player_id]: {
                    power: power - card.power,
                    cards: { [index]: { used: board.now() } },
                },
            },
        }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = cardUse
