const { GAME_ACTION } = require('../const')

function unitWalk({ action, board, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_WALK) {
        const { unit_id, tile_id } = params
        const unit = board.state.units[unit_id]
        const tile_id_from = unit.tile_id
        const path = board.getWalkablePath({
            tile_id_from,
            tile_id_to: tile_id,
        })
        action.params.path = [tile_id_from].concat(path)
        action.patch = { units: { [unit_id]: { walked: true } } }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        addReaction({
            type: GAME_ACTION.UNIT_CHANGE_TILE,
            params: { unit_id, tile_id },
        })
    }
}

module.exports = unitWalk
