//

function createRating({ rating = 1000, k = 150 } = {}) {
    return {
        rating,
        k,
    }
}

function calcRating({ me, enemy, result, kreduction = 10, kmin = 12 }) {
    const kme = me.k - (kreduction * me.k) / 100
    const kenemy = enemy.k - (kreduction * enemy.k) / 100

    return {
        me: {
            k: kme < kmin ? kmin : kme,
            rating: calc(me.rating, enemy.rating, me.k, result),
        },
        enemy: {
            k: kenemy < kmin ? kmin : kenemy,
            rating: calc(
                enemy.rating,
                me.rating,
                enemy.k,
                result == 0.5 ? 0.5 : result === 1 ? 0 : 1
            ),
        },
    }
}

function getAverageRating(team) {
    const l = team.length
    return {
        rating: team.reduce((r, p) => r + p.rating, 0) / l,
        k: team.reduce((r, p) => r + p.k, 0) / l,
    }
}

module.exports = {
    createRating,
    calcRating,
    getAverageRating,
}

// Private
function calc(a, b, k, actual) {
    const expected = 1 / (1 + Math.pow(10, (b - a) / 400))
    return Math.round(a + k * (actual - expected))
}
