const ALPHANUMERIC =
    'ModuleSymbhasOwnPr0123456789ABCDEFGHNRVfgctiUvzKqYTJkLxpZXIjQW'

function wait(timeout = 1000) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout)
    })
}

function randomInt(min, max) {
    const i = (Math.random() * 32768) >>> 0
    return (i % (min - (max + 1))) + min
}

function randomArray(array) {
    return array[randomInt(0, array.length - 1)]
}

function minMax(v, min, max) {
    return v < min ? min : v > max ? max : v
}

function uid(length = 16, chars = ALPHANUMERIC) {
    let id = ''
    while (length-- > 0) {
        id += chars.charAt(randomInt(0, chars.length - 1))
    }
    return id
}

function createUniqueId({ length = 8, list = [], chars = ALPHANUMERIC }) {
    let id
    do {
        id = uid(length, chars)
    } while (list.includes(id))
    return id
}

function convertEventNameToFunction(string) {
    return string
        .toLowerCase()
        .replace(/(_\w)/gm, (match) => match[1].toUpperCase())
}

// Object.groupBy polyfill
function groupBy(values, keyFinder) {
    return values.reduce((a, b) => {
        const key =
            typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder]

        a[key] = !a[key] ? [b] : [...a[key], b]

        return a
    }, {})
}

function groupRepeatedItems(list) {
    const counts = list.reduce((items, value) => {
        items[value] = (items[value] || 0) + 1
        return items
    }, {})

    return Object.keys(counts)
        .map((value) => ({ value, count: counts[value] }))
        .sort((a, b) => b.count - a.count)
}

module.exports = {
    wait,
    randomInt,
    randomArray,
    minMax,
    uid,
    createUniqueId,
    convertEventNameToFunction,
    groupBy,
    groupRepeatedItems,
}
