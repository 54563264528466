const { GAME_ACTION, WINNING_REASON } = require('../const')

function winner({ action, addReaction, applyPatch, board }) {
    const { type, params } = action

    if (type === GAME_ACTION.WINNER) {
        action.patch = { winner: { ...params, time: board.now() } }
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }

    // ANNIHILATION
    if (type === GAME_ACTION.UNIT_DEATH) {
        // WINNER
        const [team_id1, team_id2] = Object.keys(board.state.teams)
        const units_team1 = board.getUnitsByTeam({ team_id: team_id1 })
        const units_team2 = board.getUnitsByTeam({ team_id: team_id2 })
        const { unit_id_target } = params
        const total_units1 =
            units_team1.length - (units_team1.includes(unit_id_target) ? 1 : 0)
        const total_units2 =
            units_team2.length - (units_team2.includes(unit_id_target) ? 1 : 0)

        if (total_units1 === 0 || total_units2 === 0) {
            addReaction({
                type: GAME_ACTION.WINNER,
                params: {
                    team_id: total_units1 === 0 ? team_id2 : team_id1,
                    reason: WINNING_REASON.ANNIHILATION,
                },
            })
        }
    }

    // FLAGS
    if (type === GAME_ACTION.FLAG_CAPTURED) {
        const { team_id } = params
        if (
            board.getFlagsByTeam({ team_id }).length >= board.state.flags_to_win
        ) {
            addReaction({
                type: GAME_ACTION.WINNER,
                params: {
                    team_id,
                    reason: WINNING_REASON.FLAGS,
                },
            })
        }
    }
}

module.exports = winner
