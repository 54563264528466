// Rules
const basicPatch = require('./basicPatch')
const setBoard = require('./setBoard')
const turnStart = require('./turnStart')
const turnEnd = require('./turnEnd')
const recruit = require('./recruit')
const recruitAuto = require('./recruitAuto')
const flagAdd = require('./flagAdd')
const flagCaptured = require('./flagCaptured')
const unitAdd = require('./unitAdd')
const unitRemove = require('./unitRemove')
const unitChangeTile = require('./unitChangeTile')
const unitWalk = require('./unitWalk')
const unitLife = require('./unitLife')
const unitAttack = require('./unitAttack')
const unitDeath = require('./unitDeath')
const iconUnitAdd = require('./iconUnitAdd')
const iconUnitRemove = require('./iconUnitRemove')
const cardUse = require('./cardUse')
const cardReactionAdd = require('./cardReactionAdd')
const cardReactionRemove = require('./cardReactionRemove')
const winner = require('./winner')

// Card Rules
const CARDS = require('../cards/')
const cards = Object.keys(CARDS).map((type) => CARDS[type].rule)

module.exports = [
    basicPatch,
    setBoard,
    turnStart,
    turnEnd,
    recruit,
    recruitAuto,
    flagAdd,
    flagCaptured,
    unitAdd,
    unitRemove,
    unitChangeTile,
    unitWalk,
    unitLife,
    unitAttack,
    unitDeath,
    iconUnitAdd,
    iconUnitRemove,

    // CARDS
    ...cards,
    cardUse,
    cardReactionAdd,
    cardReactionRemove,

    // WINNER
    winner,
]
