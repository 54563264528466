const { UNIT_TYPE } = require('../const')

function KNIGHT({ players, tile_id }) {
    return {
        unit_type: UNIT_TYPE.KNIGHT,
        bonus: [UNIT_TYPE.CROSSBOWOMAN, UNIT_TYPE.SLINGER],
        players,
        tile_id,
        life: 3,
        maxlife: 3,
        movement: 4,
        range: [1, 1],
        damage: 1,
        walked: false,
        attacked: false,
        icons: {},
    }
}

module.exports = KNIGHT
