const { GAME_ACTION } = require('../const')

function basicPatch({ action, applyPatch }) {
    const { type, params } = action
    if (type === GAME_ACTION.BASIC_PATCH) {
        action.patch = params
        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch
    }
}

module.exports = basicPatch
