const package = require('../package.json')
const Board = require('./constructors/Board')
const Glicko = require('./utils/glicko')
const Elo = require('./utils/elo')
const Xp = require('./utils/xp')
const Trophies = require('./utils/trophies')
const Actions = require('./constructors/Actions')
const BOARDS = require('./boards/')
const RULES = require('./rules/')
const CARDS = require('./cards/')
const UNITS = require('./units/')
const CONSTS = require('./const/')

module.exports = {
    version: package.version,
    Board,
    Elo,
    Glicko,
    Trophies,
    Xp,
    Actions,
    BOARDS,
    RULES,
    CARDS,
    UNITS,
    ...CONSTS,
}
