const STATUS = {
    WAITING: 0,
    RUNNING: 1,
    COMPLETED: 2,
}

function createQueueAnimations({ emitter, keys, onEndQueue = () => {} }) {
    const queues = {}
    const inc = { queue: 0, action: 0 }

    // API
    // API
    // API

    function create() {
        const id = inc.queue++
        return (queues[id] = { id, actions: [] })
    }

    function on(type, fn = {}) {
        emitter.on(type, (args) => {
            const { params } = args
            const queue = find(params, type) || create()
            queue.actions.push({
                id: inc.action++,
                fn,
                args,
                type,
                status: STATUS.WAITING,
            })
            run(queue)
        })
    }

    // Private
    // Private
    // Private

    async function run(queue) {
        if (queue.current === undefined) {
            queue.current = 0
            do {
                const { fn, args, status } = queue.actions[queue.current]
                // console.log([
                //     'q:' + queue.id,
                //     args.id,
                //     '🆗 ' + type,
                //     args.id,
                //     queues.hasOwnProperty(queue.id),
                // ])
                if (status === STATUS.WAITING) {
                    queue.actions[queue.current].status = STATUS.RUNNING
                    await fn(args, queue)
                    queue.actions[queue.current].status = STATUS.COMPLETED
                }
                // console.log([
                //     'q:' + queue.id,
                //     args.id,
                //     '✅ ' + type,
                //     args.id,
                //     queues.hasOwnProperty(queue.id),
                // ])
                queue.current = queue.current + 1
            } while (
                queues.hasOwnProperty(queue.id) &&
                queue.actions[queue.current] !== undefined
            )

            if (queues.hasOwnProperty(queue.id)) {
                onEndQueue(queues[queue.id])
                delete queues[queue.id]
            }
        }
    }

    function find(params, type) {
        const found = Object.keys(queues)
            .map((id) => queues[id])
            .filter((queue) => {
                return true
                const params_args = getParams(params)
                const params_actions = queue.actions.map((action) =>
                    getParams(action.args.params)
                )
                return params_actions.some((params) =>
                    params_args.some((param) => params.includes(param))
                )
            })

        // if (found.length > 1) {
        //     merge(found)
        // }

        return found[0]
    }

    function merge(list) {
        const [destiny] = list
        list.slice(1).forEach((queue) => {
            destiny.actions = destiny.actions.concat(
                queue.actions.slice(queue.current + 1)
            )
            delete queues[queue.id]
        })
        // destiny.actions.sort((a, b) => a.id - b.id)
    }

    function getParams(params) {
        return Object.keys(params)
            .filter((key) => keys.includes(key))
            .map((key) => params[key])
    }

    return { queues, find, on }
}

module.exports = createQueueAnimations
