function getTileId(x, y) {
    return `${x}.${y}`
}

function getCoordByTile(tile_id) {
    const coord = tile_id.split('.')
    return [Number(coord[0]), Number(coord[1])]
}

function getDistanceFromPoints({ x1, y1, x2, y2 }) {
    const x = x1 > x2 ? x1 - x2 : x2 - x1
    const y = y1 > y2 ? y1 - y2 : y2 - y1
    return Math.max(x, y)
}

module.exports = {
    getTileId,
    getCoordByTile,
    getDistanceFromPoints,
}
